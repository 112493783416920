import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

const AdminPanel = () => {
  const [contact, setContacts] = useState([]);
  const [complaint, setComplaints] = useState([]);
  const [showContent, setShowContent] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Add login state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  // Dummy admin credentials (you can fetch these from backend)
  const adminUser = {
    username: "admin",
    password: "updesh1",
  };

  const handleLogin = () => {
    if (username === adminUser.username && password === adminUser.password) {
      setIsLoggedIn(true);
    } else {
      alert("Invalid username or password");
    }
  };

  const handleContactClick = () => {
    setShowContent("contacts");
    setLoading(true); // Set loading to true when starting the request
    axios
      .get('https://unisafedigital-latest-reactapp-server.vercel.app/api/contacts') // Adjust as needed
      .then((response) => {
        setContacts(response.data);
        setLoading(false); // Set loading to false when data is received
      })
      .catch((error) => {
        console.error("Error fetching contacts:", error);
        setLoading(false); // Set loading to false in case of an error
      });
  };

  const handleManageContentClick = () => {
    setShowContent("complaints");
    setLoading(true); // Set loading to true when starting the request
    axios
      .get('https://unisafedigital-latest-reactapp-server.vercel.app/api/complaints') // Adjust as needed
      .then((response) => {
        setComplaints(response.data);
        setLoading(false); // Set loading to false when data is received
      })
      .catch((error) => {
        console.error("Error fetching complaints:", error);
        setLoading(false); // Set loading to false in case of an error
      });
  };

  if (!isLoggedIn) {
    return (
      <Container maxWidth="sm" sx={{ mt: 5, mb: 5 }}>
        <Typography variant="h5" gutterBottom>
          Admin Login
        </Typography>
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
        >
          Login
        </Button>
      </Container>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      {/* Sidebar */}
      <Box
        sx={{
          width: "240px",
          backgroundColor: "#f5f5f5",
          height: "100vh",
          padding: "20px",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Admin Panel
        </Typography>
        <Box>
          <Button fullWidth variant="outlined">
            Dashboard
          </Button>
          <Button fullWidth variant="outlined" onClick={handleContactClick}>
            Contacts
          </Button>
          <Button fullWidth variant="outlined" onClick={handleManageContentClick}>
            Complaints
          </Button>
          <Button fullWidth variant="outlined">
            Settings
          </Button>
        </Box>
      </Box>

      {/* Main content */}
      <Box sx={{ flexGrow: 1, padding: "20px" }}>
        {/* Header */}
        <AppBar position="static" sx={{ marginBottom: "20px" }}>
          <Toolbar>
            <Typography variant="h6">Admin Dashboard</Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <Button color="inherit">Profile</Button>
              <Button color="inherit" onClick={() => setIsLoggedIn(false)}>
                Logout
              </Button>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Dashboard Content */}
        <Container>
          <Grid container spacing={2}>
            {/* Show loading spinner if loading is true */}
            {loading && (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <CircularProgress />
                <Typography variant="body1">Loading data, please wait...</Typography>
              </Grid>
            )}

            {/* Contacts Section */}
            {!loading && showContent === "contacts" && (
              <Grid item xs={12}>
                <Paper>
                  <Typography variant="h6" sx={{ margin: "20px" }}>
                    Contacts List
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Phone</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contact.map((contact) => (
                        <TableRow key={contact.id}>
                          <TableCell>{contact.name}</TableCell>
                          <TableCell>{contact.email}</TableCell>
                          <TableCell>{contact.message}</TableCell>
                          <TableCell>{contact.phone}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            )}

            {/* Complaints Section */}
            {!loading && showContent === "complaints" && (
              <Grid item xs={12}>
                <Paper>
                  <Typography variant="h6" sx={{ margin: "20px" }}>
                    Complaint List
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Complain</TableCell>
                        <TableCell>CCTV Problems</TableCell>
                        <TableCell>File</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {complaint.map((complaint) => (
                        <TableRow key={complaint.id}>
                          <TableCell>{complaint.name}</TableCell>
                          <TableCell>{complaint.email}</TableCell>
                          <TableCell>{complaint.phone}</TableCell>
                          <TableCell>{complaint.address}</TableCell>
                          <TableCell>{complaint.complain}</TableCell>
                          <TableCell>{complaint.cctvProblems}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default AdminPanel;
