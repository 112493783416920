import React from "react";
import { Link } from "react-router-dom";

export default function Products() {
  return (
    <>
      
     {/* banner */}
     <section className="page-banner bg_cover p-r z-1 " style={{backgroundImage: 'url(assets/images/bg/footer-bg-1.webp)' }}>
            
            <div className="container ">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="page-title">
                            <h1>Products </h1>
                            <ul className="breadcrumbs-link">
                                <li><Link to="/">Home</Link></li>
                                <li className="active">Products </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      {/* banner */}
      <section className="product-section">
  <div className="project-grid-page pt-50 ">
    <div className="container text-center">
      <h1 className="heading display-4">We Provide Many Products & Services</h1>
      <p className="lead">Explore our wide range of security, solar, and power solutions.</p>
    </div>
  </div>
</section>

<section className="project-grid pt-50 pb-50" id="project-filter">
  <div className="container">
    <div className="row">
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInUp mb-30">
          <div className="img-holder">
            <img src="assets/images/products/camera/1.webp" alt="Product 1" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 1</h5>
                <p>Camera System</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/camera/2.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 2</h5>
                <p>Camera System</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/camera/3.webp" alt="Product 3" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 3</h5>
                <p>Camera System</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/camera/4.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 4</h5>
                <p>Camera System</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/attenden_machine/1.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 5</h5>
                <p>Attenden Machine</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/attenden_machine/2.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 6</h5>
                <p>Attenden Machine</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/attenden_machine/3.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 7</h5>
                <p>Attenden Machine</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/attenden_machine/4.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 8</h5>
                <p>Attenden Machine</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/solar/1.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 9</h5>
                <p>Solar System</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/solar/2.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 10</h5>
                <p>Solar System</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/solar/3.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 11</h5>
                <p>Solar System</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/solar/4.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 12</h5>
                <p>Solar System</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/batteries/1.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 13</h5>
                <p>solar Batteries</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/batteries/2.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 14</h5>
                <p>solar Batteries</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/batteries/3.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 15</h5>
                <p>solar Batteries</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-6">
        <div className="project-item wow fadeInDown mb-30">
          <div className="img-holder">
            <img src="assets/images/products/batteries/4.webp" alt="Product 2" className="img-fluid" />
            <div className="overlay">
              <div className="overlay-content">
                <h5>Product 16</h5>
                <p>solar Batteries</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      
      
    </div>
  </div>
</section>
      
    </>
  );
}
