import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure Toastify styles are imported
import "./ComplainRegister.css";
import { IoIosCall } from "react-icons/io";
import { ClipLoader } from "react-spinners"; // Add this for the loader

const ComplainRegister = () => {
  const [complain, setComplain] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    complain: "",
    cctvProblems: [],
  });

  const [loading, setLoading] = useState(false); // Loader state

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setComplain((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle checkbox changes
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setComplain((prevState) => ({
        ...prevState,
        cctvProblems: [...prevState.cctvProblems, value],
      }));
    } else {
      setComplain((prevState) => ({
        ...prevState,
        cctvProblems: prevState.cctvProblems.filter(
          (problem) => problem !== value
        ),
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submission starts
    try {
      const response = await 
      axios.post("https://unisafedigital-latest-reactapp-server.vercel.app/api/complaints",//live 
        //axios.post("http://localhost:5000/api/complaints",//local
        complain,{
        withCredentials:true,
        }
      );
      console.log("Complaint submitted:", response.data);
      toast.success("Register complain successfully");
      
      // Clear the form after submission
      setComplain({
        name: "",
        email: "",
        phone: "",
        address: "",
        complain: "",
        cctvProblems: [],
      });
    } catch (error) {
      console.error("Error submitting the complaint:", error);
      toast.error("Register complain failed");
    } finally {
      setLoading(false); // Set loading to false after submission
    }
  };

  return (
    <>
      <section
        className="about-section py-5"
        style={{ backgroundColor: "#FBFBFB" }}
      >
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="about-image-box wow fadeInLeft">
                <img
                  src="assets/images/registere-form/register-woman.gif"
                  className="img-fluid rounded"
                  alt="Team"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div
                className="contact-three_content-box"
                style={{ padding: "50px", backgroundColor: "#FBFBFB" }}
              >
                <div style={{ margin: "21px" }}>
                  <h4>Enter Your Complain</h4>
                </div>
                <div className="contact-form">
                  <form onSubmit={handleSubmit}>
                    <div className="form_group input_with_icon">
                      <i className="fas fa-user icon"></i>
                      <input
                        type="text"
                        className="form_control"
                        placeholder="Full Name"
                        name="name"
                        value={complain.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form_group input_with_icon">
                      <i className="fas fa-envelope icon"></i>
                      <input
                        type="email"
                        className="form_control"
                        placeholder="Email Address"
                        name="email"
                        value={complain.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form_group input_with_icon">
                      <i className="icon">
                        <IoIosCall />
                      </i>
                      <input
                        type="number"
                        className="form_control"
                        placeholder="Phone number"
                        name="phone"
                        value={complain.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form_group input_with_icon">
                      <i className="fas fa-home icon"></i>
                      <input
                        type="text"
                        className="form_control"
                        placeholder="Enter the home/office address"
                        name="address"
                        value={complain.address}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="form_group">
                      <label style={{ fontWeight: "900" }}>
                        What is the problem with your CCTV camera?
                      </label>
                      <div className="row">
                        
                        <div className="col-md-6">
                          <label>
                            <input
                              type="checkbox"
                              name="cctvProblems"
                              value="System doesn't respond"
                              onChange={handleCheckboxChange}
                            />
                            System doesn't respond
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label>
                            <input
                              type="checkbox"
                              name="cctvProblems"
                              value="Install new camera"
                              onChange={handleCheckboxChange}
                            />
                            Install new camera
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label>
                            <input
                              type="checkbox"
                              name="cctvProblems"
                              value="CCTV wire problem"
                              onChange={handleCheckboxChange}
                            />
                            CCTV wire problem
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label>
                            <input
                              type="checkbox"
                              name="cctvProblems"
                              value="Need CCTV maintenance"
                              onChange={handleCheckboxChange}
                            />
                            Need CCTV maintenance
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label>
                            <input
                              type="checkbox"
                              name="cctvProblems"
                              value="other"
                              onChange={handleCheckboxChange}
                            />
                            Other
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label>
                            <input
                              type="checkbox"
                              name="cctvProblems"
                              value="Add New solar plaint"
                              onChange={handleCheckboxChange}
                            />
                            Add New solar plaint
                          </label>
                        </div>
                      </div>
                    </div>
                        {/* Add more checkboxes here */}
              
                    
                    <div className="form_group input_with_icon">
                      <i className="fas fa-comments icon"></i>
                      <textarea
                        className="form_control"
                        placeholder="Please describe your problem"
                        name="complain"
                        value={complain.complain}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                    

                    <div className="form_group">
                      <button
                        className="main-btn btn-yellow"
                        type="submit"
                        disabled={loading} // Disable button while loading
                      >
                        {loading ? (
                          <ClipLoader size={20} color={"#fff"} loading={true} />
                        ) : (
                          "Send Now"
                        )}
                      </button>
                    </div>
                  </form>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComplainRegister;