import React from 'react'
import { Link } from "react-router-dom"

export default function Gallary() {
  return (
    <>
    
     {/* banner */}
     <section className="page-banner bg_cover p-r z-1 bgc" style={{backgroundImage: 'url(assets/images/bg/footer-bg-1.webp)' }}>
            
            <div className="container ">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="page-title">
                            <h1>Gallary</h1>
                            <ul className="breadcrumbs-link">
                                <li><Link to="/">Home</Link></li>
                                <li className="active">Gallary </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      {/* banner */}

      {/* gallary */}
      
      <section className="contact-information-one p-r z-1 pt-50 pb-30">

         <div className="container">
         <div className="row">
            <div className="col-md-4">
            <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FUNISAFEDIGITAL%2Fvideos%2F918449072864594%2F&show_text=false&width=476&t=0" width="100%" height="400"  frameborder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" title="mymap"></iframe>
            </div>
            <div className="col-md-4">
            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FUNISAFEDIGITAL%2Fposts%2Fpfbid0pJSKfUeu8ifdirkP5FLbkVu8AacgxchH8Kz6JxepcT4gJq2ngkp5ydUxRGF1y8ufl&show_text=false&width=500" width="100%" height="400"  frameborder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" title="mymap"></iframe>
            </div>
            <div className="col-md-4">
              
            <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FUNISAFEDIGITAL%2Fvideos%2F957909695171945%2F&show_text=false&width=476&t=0" width="100%" height="400" frameborder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"  title="mymap"></iframe>
            </div>

            <div className="col-md-4">

            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FUNISAFEDIGITAL%2Fposts%2Fpfbid0EyJte46E4b1P9a7SzbAgyB24H821J5sMyRc2e9FWqfST8LFdE9m8m2uAACzTVhc4l&show_text=false&width=500" width="100%" height="400" frameborder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" title="mymap"></iframe>
            </div>

             <div className="col-md-4">
             <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FUNISAFEDIGITAL%2Fvideos%2F745189186657490%2F&show_text=false&width=476&t=0" width="100%" height="400"  frameborder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"   title="mymap"></iframe>
              </div> 

              <div className="col-md-4">
              <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FUNISAFEDIGITAL%2Fvideos%2F1551945458658603%2F&show_text=false&width=476&t=0" width="100%" height="400"  frameborder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"  title="mymap"></iframe>
              </div>
            
            </div>    
        </div> 
    

      </section>

      {/* gallary */}
    
    </>
  )
}
