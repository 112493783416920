import React from "react";
import { CarouselItem } from "react-bootstrap";
// import { CarouselItem } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
export default function Home() {
  return (
    <>
      {/* slider */}
      <div className="ds">
        <Carousel fade>
          <CarouselItem>
            <img
              className="d-block w-100"
              src="assets/images/slider/slider11.webp"
              alt=""

              
            />
          </CarouselItem>

          <Carousel.Item>
            <img
              className="d-block w-100"
              
              src="assets/images/slider/scan-fingerprint-biometric.jpg"
              alt="First slide"
              
            />
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              
              src="assets/images/slider/slider7.webp"
              alt="First slide"
              width={"1526px"}
              height={"672px"}
            />
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/slider22.webp"
              alt="Third slide"
              width={"1526px"}
              height={"672px"}
            />
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/slider33.webp"
              alt="Third slide"
              width={"1526px"}
              height={"672px"}
            />
          </Carousel.Item>
          
        </Carousel>
      </div>

      {/* slider */}

      {/* mobile slider */}
      <div className="ms">
        <Carousel fade>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/sm1.webp"
              alt="First slide"
            />
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/sm2.webp"
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/sm3.webp"
              alt="Third slide"
            />

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="assets/images/slider/sm55.gif"
              alt="Third slide"
            />

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>

      {/*mobile slider */}

      
      <section className="about-section py-5">
  <div className="container">
    {/* About Content Section */}
    <div className="row align-items-center mb-5">
      <div className="col-lg-6 mb-4 mb-lg-0">
        <div className="about-image-box wow fadeInLeft">
          <img
            src="assets/images/about/team.webp"
            className="img-fluid rounded shadow-lg"
            alt="Team"
          />
        </div>
      </div>

      <div className="col-lg-6">
        <div className="about-content-box pl-lg-5">
          <div className="section-title section-title-left mb-4 wow fadeInUp">
            <h2 className="mb-3">
              Why Choose Us? <br />
              <strong style={{ color: "#0192d8" }}>Unisafe Digital</strong>
            </h2>
          </div>
          <p className="text-muted">
            We are a startup in the service industry business. Launched in 2015 by two partners with a dedicated team of service technicians, we are committed to providing maximum customer satisfaction.
          </p>
          <p className="text-muted">
            With a motto of service and a goal of customer satisfaction, we have achieved great success and built a strong, satisfied customer base. <span style={{fontWeight:"900"}}>Our product range includes electronic security, surveillance systems, rooftop solar plant, power inverters and batteries.</span>
          </p>
          <p className="text-muted">
            We look forward to a long and healthy association with you and the chance to serve you.
          </p>
        </div>
      </div>
    </div>

    {/* Services Section */}
    
  </div>

  {/* Custom Styles */}
  <style jsx>{`
    .about-section {
      background-color: #fff;
    }
    .about-image-box img {
      border-radius: 10px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
    .about-content-box p {
      line-height: 1.8;
    }
    .service-card {
      transition: transform 0.3s, box-shadow 0.3s;
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    }
    .service-card:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    }
    .service-card img {
      max-width: 80px;
    }
    @media (max-width: 768px) {
      .about-content-box {
        padding-left: 0;
      }
    }
  `}</style>
</section>
      

      <section className="product-section">
  <div className="project-grid-page pt-50 ">
    <div className="container text-center">
      <h1 className="heading display-4">We Provide Many Products & Services</h1>
      <p className="lead">Explore our wide range of security, solar, and power solutions.</p>
    </div>
  </div>
</section>

      <section>
        <div
          className="project-grid-page p-r z-1 pt-60 pb-60 "
          id="project-filter"
        >
          <div className="container">
            <div className="row project-row">
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-1">
                <div className="project-item-three mb-30 wow fadeInUp">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/s1.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">CCTV</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">
                    CCTV Surveillance System
                  </h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-2">
                <div className="project-item-three mb-30 wow fadeInDown">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/s2.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">ATTENDANCE MACHINE</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">
                    Access Control & Time Attendance Machine
                  </h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-3">
                <div className="project-item-three mb-30 wow fadeInUp">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/s3.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">VIDEO DOOR PHONE</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">
                    Video Door Phone and Electronic Lock
                  </h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-4">
                <div className="project-item-three mb-30 wow fadeInDown">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/s4.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">ALARM SYSTEM</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">Home Alarm System</h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-5">
                <div className="project-item-three mb-30 wow fadeInUp">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/s5.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">FIRE ALARM</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">Fire Alarm System</h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-6">
                <div className="project-item-three mb-30 wow fadeInDown">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/s6.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">INTERCOM</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">Intercom & EPABX</h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-7">
                <div className="project-item-three mb-30 wow fadeInUp">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/s7.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content ">
                        <h3 className="title">
                          <a href="/">SOLAR PLANT</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <h6 className="mt-30 text-center">Roof Top Solar Plant</h6>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-8">
                <div className="project-item-three mb-30 wow fadeInUp">
                  <div className="img-holder cat-2">
                    <img src="assets/images/services/s8.webp" alt="" />
                    <div className="hover-portfolio">
                      <div className="hover-content">
                        <h3 className="title">
                          <a href="/">BATTERIES BACKUP</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-30 text-center">
                    Invertors and Batteries Backup
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* solar power */}
      
       {/* solar power */}

      <section className="py-5">
  <div className="container text-center pb-4 section-title">
    <h2 className="title display-4 font-weight-bold customheading">
      Best Brands for CCTV Cameras, Solar & Batteries
    </h2>

    <h6 className="pt-3 text-muted font-italic">
      Unisafe Digital provides top-quality products from CPPLUS, HIKVISION, DAHUA, LUMINOUS.
    </h6>
  </div>

  <section className="pb-5 pt-5">
    <div className="container">
      <div className="row">
        {/* Brand Card 1 */}
        <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
          <div className="brand-card text-center shadow-sm p-3 rounded-lg hover-shadow">
            <img src="assets/images/partner/1.webp" alt="Brand 1" className="img-fluid" />
          </div>
        </div>
        {/* Brand Card 2 */}
        <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
          <div className="brand-card text-center shadow-sm p-3 rounded-lg hover-shadow">
            <img src="assets/images/partner/2.webp" alt="Brand 2" className="img-fluid" />
          </div>
        </div>
        {/* Brand Card 3 */}
        <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
          <div className="brand-card text-center shadow-sm p-3 rounded-lg hover-shadow">
            <img src="assets/images/partner/3.webp" alt="Brand 3" className="img-fluid" />
          </div>
        </div>
        {/* Brand Card 4 */}
        <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
          <div className="brand-card text-center shadow-sm p-3 rounded-lg hover-shadow">
            <img src="assets/images/partner/4.webp" alt="Brand 4" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </section>
</section>

<style jsx>{`
  .customheading {
    font-size: 2.5rem;
    color: #333;
  }

  .brand-card {
    background: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .brand-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .hover-shadow:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    .title {
      font-size: 1.75rem;
    }
  }
`}</style>
    </>
  );
}
