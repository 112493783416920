import { Link } from "react-router-dom";
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoIosCall } from "react-icons/io";
import { ClipLoader } from "react-spinners"; 

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const [loading,setLoading]=useState(false);
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://unisafedigital-latest-reactapp-server.vercel.app/api/contacts', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,  // In case credentials (cookies or tokens) are needed
      });

      if (response.data.success) {
        toast.success('Message sent successfully!');
        // Reset form after successful submission
        setFormData({
          name: '',
          email: '',
          message: '',
          phone: '',
        });
        
      } else {
        toast.error('Failed to send message.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
    setLoading(false);
  };

  return (
    <>
      {/* banner */}
      <section
        className="page-banner bg_cover p-r z-1"
        style={{ backgroundImage: "url(assets/images/bg/footer-bg-1.webp)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="page-title">
                <h1>Contact us</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner */}

      <section className="contact-information-one p-r z-1 pt-50 pb-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="contact-two_information-box text-center">
                <di7v className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="information-item-two info-one mb-30 wow fadeInDown">
                      <div className="icon">
                        <i className="far fa-map-marker-alt"></i>
                      </div>
                      <div className="info">
                        <h5>Office Address</h5>
                        <p>
                          <a
                            href="https://goo.gl/maps/vnU79ktjY9CM8SVc9"
                            target="blank"
                          >
                            G-23,B14 <br />
                            Shree mansion,kamla marg,C-Scheme,Jaipur
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="information-item-two mb-30 info-three wow fadeInDown">
                      <div className="icon">
                      <i><IoIosCall /></i>
                      </div>
                      <div className="info">
                        <h5>Phone Number</h5>
                        <p>
                          <a href="tel:+917340044005">+91 7340044005</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="information-item-two mb-30 info-two wow fadeInUp">
                      <div className="icon">
                        <i className="far fa-envelope-open-text"></i>
                      </div>
                      <div className="info">
                        <h5>Email Address</h5>
                        <p>
                          <a href="mailto:unisafediginsolar@gmail.com">
                            unisafediginsolar@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </di7v>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-50 pt-50">
        <div className="container ">
          <div className="row ">
            <div className="col-md-6">
              <div className="map-box ">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14230.757000450081!2d75.78874603955077!3d26.913355600000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5127f54b695%3A0xaaf8f35baa4679f2!2sUnisafe%20Digital!5e0!3m2!1sen!2sin!4v1659266405184!5m2!1sen!2sin"
                  width="100%"
                  height="620"
                  title="mymap"
                  style={{ border: "2 solid" }}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-three_content-box">
                     <div style={{margin:'21px'}}>
                <h4>Enter Your Query</h4>
              </div>
                <div className="contact-form">
                  <form onSubmit={handleSubmit}>
                    <div className="form_group">
                      <input
                        type="text"
                        className="form_control"
                        placeholder="Full Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form_group">
                      <input
                        type="email"
                        className="form_control"
                        placeholder="Email Address"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form_group">
                      <input
                        type="number"
                        className="form_control"
                        placeholder="Contect Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form_group">
                      <textarea
                        className="form_control"
                        placeholder="Write Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                    <div className="form_group">
                      <button className="main-btn btn-yellow" type="submit"
                      disabled={loading}>
                      {loading ? (
                        <ClipLoader size={20} color={"#fff"} loading={true}/>
                      ):(
                        "Send Now"
                      )}
                      </button>
                    </div>
                  </form>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
